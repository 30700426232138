export const PATHS ={
    game:(game)=>`/game/${game.id}`,
    home() {
return "/"
    },
    create() {
        return "/create"
    },
    login() {
return "/login"
    }
}