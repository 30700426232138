import {api} from "./api";

export const UserService = {
    currentUser: async () => {
        const response = await api.auth.currentUser();
        return response.data
    },

    updateUser: async (username) => {
        const response = await api.auth.updateUser(username)
        return response.data
    }
}