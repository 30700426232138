import React, {useContext} from 'react';
import './CurrentCard.scss';
import DraggableShape from './DraggableShape';
import Tile from "../Tile";
import {PreviewContext} from "../../../contexts/PreviewContext";
import {canPlaceCard, canPlaceShape} from "../../../utils/utils";

function CurrentCard({board,card, season,active}) {

    let {name, value, shapes, types, isRuin, coins, id} = card;
    if(active && !canPlaceCard(board,card,season)){
        shapes = [[[0,0]]]
        types=["monster","village","field","forest","ocean"];
    }
    let activeClass = active ? " " : "disabled";
    let shapeClass= types.length===5?" shapes-5":" ";
    return (
        <div className={`current-card card-shapes `+activeClass}>
            {/*<img src="/assets/cards/red-card-bg.png" alt="" className={"background"}/>*/}
            <div className={"card-header"}>
                {season.ruinActive && <div className={"map-tile ruin"}></div>}
                {!season.ruinActive && <div className={"map-tile"}></div>}
                <div className={"card-name"}>{name}</div>
                <div className={"map-tile card-value"}>{value}</div>
            </div>
            <div className={"shapes "+ activeClass+ shapeClass}>
                {types.map(type=>shapes.map((shape, shapeIndex) => (
                    <DraggableShape
                        key={"shape-"+type+"-"+name+"-"+shapeIndex}
                        shape={shape}
                        type={type}
                        isRuin={isRuin}
                        active={active}
                        shapeKey={`${id}-${type}-${shapeIndex}`}
                        coin={coins[shapeIndex]}
                    />
                ))).flatMap((values)=>values)}
            </div>

        </div>
    );
}

export default CurrentCard;
