import {LoadingCircle} from "../LoadingCircle";
import classes from "./SplashScreen.module.scss"

export function SplashScreen(props){
    let title = props.title??"Loading";
    let message = props.message??"";
    return <div className={"container "}>
        <div className={classes.splashScreen+" content"}>
            <h1>{title}</h1>
            <small>{message}</small>
            <LoadingCircle/>
        </div>
    </div>
}