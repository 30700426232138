import {Navigate} from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {PATHS} from "../../services/paths";
import {SplashScreen} from "../Home/SplashScreen";

export const ProtectedRoute = ({  children }) => {
    const { user,loading } = useContext(AuthContext);
    if(loading){
        return <SplashScreen/>
    }
    if (!user) {
        return <Navigate to={PATHS.login()} replace />;
    }
    return children;
};