import React from 'react';
import "./MapTile.scss"
function Tile({ content,ruin }) {


    return (
        <div className={`map-tile ${content} `}>
            {ruin && <div className={"map-tile ruin"}></div>}
        </div>
    );
}

export default Tile;
