import React, {createContext, useEffect, useState} from 'react';
import {api} from "../services/api";
import {useCookies} from "react-cookie";
import {AuthService} from "../services/auth";
import {UserService} from "../services/user";

export const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [{userCookie}, setCookie] = useCookies(["userCookie"])
    const [user, setUser] = useState(userCookie ?? null);
    const [idToken, setIdToken] = useState(undefined);
    useEffect(() => {
        const unsubscribe = AuthService.onAuthStateChange(async userToken => {
            if (userToken) {
                const userHasChanged = userToken !== idToken;
                if (userHasChanged) {
                    setIdToken(userToken)
                    handleUserUpdate(await UserService.currentUser());
                }
            } else {
                handleUserUpdate(null)
                setIdToken(null)
            }
        })
        return () => {
            unsubscribe();
        }
    }, [])
    const anonymousLogin = async (username) => {
        const userToken = await AuthService.loginAnonymous(username);
        let user = await UserService.updateUser(username)
        handleUserUpdate(user);
        return user;
    };
    const emailLogin = async (email, password) => {
        const userToken = await AuthService.loginWithEmail(email, password)
        const user = await UserService.currentUser();
        handleUserUpdate(user);
        return user;
    }

    const emailSignup = async (email, password, username) => {
        const userToken = await AuthService.signUpWithEmail(email, password);
        const user = await UserService.updateUser(username)
        handleUserUpdate(user);
        return user
    }

    const logout = async () => {
        await AuthService.logout();
        handleUserUpdate(null);
    }

    function handleUserUpdate(user) {
        setUser(user)
        setCookie("userCookie", user)
    }


    const loading = idToken===undefined || (idToken!==null && user===null);
    return (
        <AuthContext.Provider value={{user, loading: loading, anonymousLogin, emailLogin, emailSignup, logout}}>
            {children}
        </AuthContext.Provider>
    );
};
