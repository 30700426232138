// Assuming you have an API endpoint for joining a game

import {api} from "./api";
import async from "async";
import {io} from "socket.io-client";

export const joinGame = async (game, playerId, username) => {
    try {
        const response = await api.game.join(game.id, {playerId: playerId, username});

        return response.data;
    } catch (error) {
        throw new Error('Failed to join the game.'); // You can handle the error in the calling component
    }
};
export const createGame = async (playerId, gameName) => {
    try {
        const response = api.game.create({creatorId: playerId, gameName});
        return response;
    } catch (error) {
        throw new Error('Error creating game. Please try again.'); // Handle any errors that occur during game creation
    }
};
export const subscribe = (playerId, userName, gameId, onStateUpdate, onError) => {
    // Connect to the socket.io server
    const newSocket = io(api.SOCKET_ROOT_URL, {path: api.SOCKET_PATH, transports: ["websocket"]});
    // Emit an event to join the game room
    newSocket.emit('watchGame', {gameId: gameId});

    // Handle incoming game state updates
    newSocket.on('gameState', (updatedGameState) => {
        onStateUpdate(updatedGameState)
    });
    if (onError) {
        newSocket.on("error", onError)
    } else {
        newSocket.on('error', (error) => {
            console.error(error)
        })
    }

    // Clean up on unmount
    return {
        close: () => {
            newSocket.disconnect();
        },
        actions: {
            join(playerId, username) {
                newSocket.emit('joinGame', {gameId, userId: playerId, username})
            },
            placeShape(shape) {
                newSocket.emit('placeShape', {gameId: gameId, userId: playerId, item: shape})
            },
            ready(playerId) {
                newSocket.emit("playerReady", {gameId: gameId, userId: playerId})
            },
            reset() {
                newSocket.emit("reset", {gameId})
            },
            kick(player) {
                newSocket.emit("kick", {gameId, userId: null, targetId: player.id})
            }
        }
    };
}

export async function isInGame(user) {
    return await api.game.gameOfUser(user.uid)
}

export async function listGames() {
    return (await api.game.list(false)).data
}

export async function listMyGames() {
    return (await api.game.list(true)).data
}