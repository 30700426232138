
const adjectives = [
    'Funny', 'Crazy', 'Silly', 'Quirky', 'Witty', 'Goofy', 'Cheeky', 'Whimsical', 'Zany', 'Hilarious',
    'Absurd', 'Ludicrous', 'Bizarre', 'Bonkers', 'Amusing', 'Jovial', 'Comical', 'Playful', 'Droll', 'Wacky',
    'Clever', 'Mischievous', 'Funky', 'Groovy', 'Curious', 'Odd', 'Offbeat', 'Charming', 'Chuckleworthy', 'Sarcastic',
    'Entertaining', 'Spirited', 'Eccentric', 'Jolly', 'Joyful', 'Lively', 'Animated', 'Zesty', 'Vibrant', 'Energetic',
    'Dynamic', 'Sassy', 'Saucy', 'Hysterical', 'Hysterically Funny', 'Giggly', 'Jocular', 'Unusual', 'Extraordinary', 'Remarkable',
    'Whacky', 'Far-out', 'Hysterical', 'Outrageous', 'Kooky', 'Curly', 'Zippy', 'Bubbly', 'Radiant', 'Enthusiastic',
    'Boisterous', 'Snazzy', 'Happy-go-lucky', 'Pleasant', 'Delightful', 'Fantastic', 'Happening', 'Riotous', 'Rollicking', 'Sprightly',
    'Peppy', 'Jaunty', 'Larky', 'Chipper', 'Brilliant', 'Smart', 'Sharp', 'Bright', 'Genius', 'Savvy',
    'Intelligent', 'Shrewd', 'Cunning', 'Astute', 'Ingenious', 'Inventive', 'Resourceful', 'Talented', 'Quick-witted', 'Crafty',
    'Sneaky', 'Cleverest', 'Brainy', 'Brave', 'Heroic', 'Fearless', 'Valiant', 'Daring', 'Courageous', 'Adventurous',
    'Gutsy', 'Bold', 'Audacious', 'Intrepid', 'Spunky', 'Tenacious', 'Indomitable', 'Feisty', 'Fear-defying', 'Dauntless',
    'Unafraid', 'Unflinching', 'Resolute', 'Persistent', 'Strong-willed', 'Determined', 'Hardy', 'Stalwart', 'Unyielding', 'Unshakeable',
    'Rugged', 'Robust', 'Sturdy', 'Powerful', 'Mighty', 'Energetic', 'Vigorous', 'Invincible', 'Unbeatable', 'Unassailable',
    'Fierce', 'Untouchable', 'Relentless', 'Undaunted', 'Unswerving', 'Loyal', 'Trustworthy', 'Devoted', 'Dedicated', 'Faithful',
    'Dependable', 'Steadfast', 'Rock-solid', 'Resilient', 'Unchanging', 'Unshakable', 'Unwavering', 'Immovable', 'Impermeable', 'Indestructible',
    'Indefatigable', 'Persevering', 'Iron-willed', 'Tenacious', 'Steady', 'Unflagging', 'Constant', 'Enduring', 'Timeless', 'Everlasting',
    'Evergreen'];

const nouns = [
    'Penguin', 'Banana', 'Kangaroo', 'Noodle', 'Pancake', 'Potato', 'Sausage', 'Cupcake', 'Jellybean', 'Donut',
    'Toaster', 'Pickle', 'Marshmallow', 'Panda', 'Cactus', 'Watermelon', 'Pineapple', 'Bubble', 'Cookie', 'Flamingo',
    'Lion', 'Tiger', 'Bear', 'Elephant', 'Giraffe', 'Zebra', 'Koala', 'Monkey', 'Sloth', 'Cheetah',
    'Dolphin', 'Whale', 'Shark', 'Octopus', 'Seahorse', 'Starfish', 'Jellyfish', 'Crab', 'Lobster', 'Seal',
    'Dragon', 'Unicorn', 'Phoenix', 'Mermaid', 'Wizard', 'Witch', 'Fairy', 'Centaur', 'Troll', 'Goblin',
    'Robot', 'Alien', 'Astronaut', 'Rocket', 'Spaceship', 'Satellite', 'UFO', 'Stardust', 'Meteor', 'Comet',
    'Pizza', 'Burger', 'Taco', 'Sushi', 'Pasta', 'Salad', 'Burrito', 'Ice Cream', 'Popsicle', 'Cup of Coffee',
    'Guitar', 'Drums', 'Piano', 'Violin', 'Trumpet', 'Saxophone', 'Flute', 'Harmonica', 'Accordion', 'Maracas',
    'Sunflower', 'Rose', 'Daisy', 'Tulip', 'Lily', 'Orchid', 'Cactus', 'Palm Tree', 'Maple Tree', 'Oak Tree',
    'Mountain', 'Beach', 'Forest', 'Desert', 'Waterfall', 'Volcano', 'Cave', 'Canyon', 'Lake', 'River',
    'Book', 'Pen', 'Pencil', 'Notebook', 'Easel', 'Paintbrush', 'Scissors', 'Ruler', 'Eraser', 'Calculator',
    'Cloud', 'Rainbow', 'Lightning', 'Thunder', 'Wind', 'Snowflake', 'Raindrop', 'Sun', 'Moon', 'Star',
];


const generateRandomUsername = () => {
    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
    return `${randomAdjective}${randomNoun}`;
};
export default generateRandomUsername;
export function rotateCCW(points){
    // Calculate the centroid of the shape
    let centroid = points.reduce(([xSum, ySum], [x, y]) => [xSum + x, ySum + y], [0, 0])
        .map(coordinateSum => coordinateSum / points.length);

    // Translate the points so that the centroid is at the origin
    let translatedPoints = points.map(([x, y]) => [x - centroid[0], y - centroid[1]]);

    // Rotate the translated points counter-clockwise
    let rotatedPoints = translatedPoints.map(([x, y]) => [y, -x]);

    // Translate the rotated points back, then round to the nearest integer
    return rotatedPoints.map(([x, y]) => [Math.round(x + centroid[0]), Math.round(y + centroid[1])]);
}

function adjustInBound(shape) {
  const shift = adjustPositions(shape,[0,0]);
  return shape.map(([x,y])=>[x+shift[0],y+shift[1]])
}

export  function rotateCW(points) {
    // Calculate the centroid of the shape
    let centroid = points.reduce(([xSum, ySum], [x, y]) => [xSum + x, ySum + y], [0, 0])
        .map(coordinateSum => coordinateSum / points.length);

    // Translate the points so that the centroid is at the origin
    let translatedPoints = points.map(([x, y]) => [x - centroid[0], y - centroid[1]]);

    // Rotate the translated points counter-clockwise
    let rotatedPoints = translatedPoints.map(([x, y]) => [-y, x]);

    // Translate the rotated points back, then round to the nearest integer
    return rotatedPoints.map(([x, y]) => [Math.round(x + centroid[0]), Math.round(y + centroid[1])]);


}
export function mirrorVertically(points) {
    return points.map(([x, y]) => [x, -y]);
}

export function mirrorHorizontally(points) {
    return points.map(([x, y]) => [-x, y]);
}

export function computePosition(preview) {
    const {x, y, shape} = preview;
    let positions = shape.shape.map(([i, j]) => [x + i, y + j]);
    if (preview.shape.origin !== undefined) {
        const origin = preview.shape.origin;
        positions = positions.map(([i, j]) => {
            return ([i - origin[0], j - origin[1]]);
        })
    }
    return positions;
}

function adjustPositions(positions, position) {
    // Calculate the minimum and maximum extents of the shape
    let minX = Math.min(...positions.map(([x, _]) => x));
    let minY = Math.min(...positions.map(([_, y]) => y));
    let maxX = Math.max(...positions.map(([x, _]) => x));
    let maxY = Math.max(...positions.map(([_, y]) => y));

    // If the shape extends out of the bounds, adjust its position
    let newPosition = [...position];
    if (minX < 0) newPosition[0] -= minX;
    if (minY < 0) newPosition[1] -= minY;
    if (maxX >= 11) newPosition[0] -= maxX - 11 + 1;
    if (maxY >= 11) newPosition[1] -= maxY - 11 + 1;

    return newPosition;
}

export function checkInBound(shape,position){
    let positions = shape.shape.map(([x, y]) => [x + position[0], y + position[1]]);
    if (shape.origin !== undefined) {
        const origin = shape.origin;
        positions = positions.map(([i, j]) => {
            return ([i - origin[0], j - origin[1]]);
        })
    }
    // Calculate the extents of the shape in its current position
    return adjustPositions(positions, position);
}
export function isPlacementValid(shape,board,card,season,shapeOveride){
    let positions = computePosition(shape);
    return positions.every(([x,y])=>board[y][x].content===null) && (shapeOveride || (!season.ruinActive ||  positions.some(([x,y])=>board[y][x].isRuin)))
}

export function getNextPlayer(gameState, user, boardOffset) {
    let index = (gameState.playerOrder.indexOf(user.uid) + boardOffset) % gameState.playerOrder.length;
    if (index < 0) {
        index = gameState.playerOrder.length + index
    }
    return gameState.players[gameState.playerOrder[index]]
}


export function canPlaceShape(grid, shape,mustRuin) {
    function canPlaceAtPosition(grid, shape, shapeX,shapeY) {
        let positions = shape.map(([x,y])=>[x+shapeX,y+shapeY]);
        for (let [x,y] of positions){
            if(x<0 || y<0 ||x>=grid.length || y>=grid.length){
                return false
            }
            if(grid[y][x].content!==null){
                return false
            }
        }
        if(mustRuin){
            return positions.some(([x,y])=>grid[y][x].isRuin)
        }
        return true;
    }
    function canPlace(grid,shape){
        return grid.some((row)=>row.some(t=>canPlaceAtPosition(grid,shape,t.x,t.y)))
    }

    // Try all possible rotations and mirrors of the shape
    for (let rotation = 0; rotation < 4; rotation++) {
        for (let mirror = 0; mirror < 2; mirror++) {
            if (canPlace(grid, shape)) {
                return true;
            }
            shape = mirrorVertically(shape);
        }
        shape = rotateCW(shape);
    }

    return false;
}
export function canPlaceCard(board,card,season){
    return card.shapes.some((shape)=>canPlaceShape(board,shape,season.ruinActive))
}