// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {
    getAuth,
    signInAnonymously,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut
} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAh6J4l6noX_3_iFfEK5s8OqjUYtyps3r8",
    authDomain: "qartographer.firebaseapp.com",
    projectId: "qartographer",
    storageBucket: "qartographer.appspot.com",
    messagingSenderId: "113830385118",
    appId: "1:113830385118:web:ef0555da5858be3790b94f",
    measurementId: "G-7TGBZ298RJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const authStateListeners = [];

auth.onAuthStateChanged(async (user) => {
    let idToken = null;
    if (user) {
        idToken = await user.getIdToken();
    }
    authStateListeners.filter(listener => listener !== null).forEach(listener => listener(idToken));
})
export const AuthService = {
    async loginAnonymous() {
        console.log(auth)
        const user = await signInAnonymously(auth)
        return await user.user.getIdToken()
    },
    async loginWithEmail(email, password) {
        const credentials = await signInWithEmailAndPassword(auth, email, password);
        return await credentials.user.getIdToken();
    },
    async logout() {
        await signOut(auth);
    },
    onAuthStateChange(callback) {
        const index = authStateListeners.push(callback) - 1;
        return () => {
            authStateListeners[index] = null;
        }
    },
    async getIdToken(){
        return await auth.currentUser?.getIdToken();
    }
}
