import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {PATHS} from "../services/paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBurger, faUser} from "@fortawesome/free-solid-svg-icons";
import "./UserTag.scss"
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";

export function UserTag(props) {
    const navigate = useNavigate()
    const {user, logout} = useContext(AuthContext);
    const [active, setActive] = useState(false)
    useEffect(() => {
        if (active) {
            const touchCloseOpenMenus = (e) => {
                closeOpenMenus(e)
            }
            document.addEventListener("touchstart", touchCloseOpenMenus)
            document.addEventListener('mousedown', closeOpenMenus)
            return () => {
                document.removeEventListener("mousedown", closeOpenMenus)
                document.removeEventListener("touchstart", touchCloseOpenMenus)

            }
        }

    }, [active])
    const handleHome = () => {
        navigate(PATHS.home());
    }
    const handleLogout = () => {
        logout();
    }
    const handleMenu = () => {
        setActive(!active);
    }
    const userMenu = useRef(null)

    function closeOpenMenus(e) {
        console.log(userMenu.current, !userMenu.current.contains(e.target))
        if (userMenu.current && active && !userMenu.current.contains(e.target)) {
            setActive(false)
        }
    }

    return <div className={"user-tab"}>
        <button className={"round-button"} onClick={handleMenu}><FontAwesomeIcon icon={faBars}/></button>
        <div className={"user-menu " + (active ? "active" : "")} ref={userMenu}>
            <div>{user.username}</div>
            <button className={"logout"} onClick={logout}>Logout</button>
        </div>
    </div>
}