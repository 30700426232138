import React, {useContext, useState} from 'react';
import {AuthContext} from "../../contexts/AuthContext";
import classes from "./Login.module.scss"
import generateRandomUsername from "../../utils/utils";
import {PATHS} from "../../services/paths";
import {useNavigate} from "react-router-dom";
import {LoadingCircle} from "../LoadingCircle";

function Login(props) {
    const {user, anonymousLogin} = useContext(AuthContext)
    const [username, setUsername] = useState(generateRandomUsername())
    const [isLogingIn, setIsLogingIn] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const navigate = useNavigate()
    const handlePseudoChange = (event) => {
        setUsername(event.target.value)
    }
    const handleLogin = async () => {
        setIsLogingIn(true);
        const user = await anonymousLogin(username)
        if (user) {
            setLoginSuccess(true);
            setTimeout(() => {
                setIsLogingIn(false)
                window.history.back()
            }, 1000)
        } else {
            setIsLogingIn(false)
        }
    }
    return (
        <div className={"container"}>
            <div className={"content " + classes.login}>
                <h1>Login</h1>
                <div className={"" + classes.nameInput}>
                    <div>Pick a username</div>
                    <div>
                        <input type="text" value={username} onChange={handlePseudoChange}/></div>
                    <button onClick={handleLogin}>Login</button>
                </div>

                {isLogingIn && <div className={classes["loading-overlay"]}>
                    {loginSuccess && <div>
                        <h1>Success</h1>
                        <small>Redirecting you</small>
                    </div>}
                    {!loginSuccess && <div>
                        <LoadingCircle/>
                        <h1>Logging In</h1>
                    </div>}
                </div>
                }
            </div>
        </div>
    );
}

export default Login;