import * as PropTypes from "prop-types";
import {CoinColumn} from "./CoinColumn/CoinColumn";
import MapDisplay from "./MapDisplay";
import {PlayerScores} from "./scores/PlayerScores";
import "./GameOver.scss"
import {Rules} from "./Rules/Rules";
import React from "react";
import {PATHS} from "../../services/paths";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrophy} from "@fortawesome/free-solid-svg-icons";

function total(player) {
    return Object.values(player.scores).map(s => s.total).reduce((a, b) => a + b)
}

function FinalPlayerPanel({player, position, seasons}) {
    return <div className={"player-final"}>
        <div className={"header"}>
            {position===1 &&<div className={"player-rank"}><FontAwesomeIcon icon={faTrophy}/></div>}
            {position!==1 &&<div className={"player-rank"}>#{position}</div>}
            <div className={"player-name"}>{player.username}</div>
            {position === 1 && <div className={"player-score"}>{total(player)}</div>}
        </div>
        <div className={"grid-row"}>
            <CoinColumn player={player}/>
            <MapDisplay board={player.board} attacking={null} onDragOver={() => {
            }}/>
        </div>
        {position === 1 && <PlayerScores player={player} seasons={seasons} currentIndex={5}/>}
        {position > 1 && total(player)}
    </div>

}

FinalPlayerPanel.propTypes = {
    position: PropTypes.number,
    player: PropTypes.any
};

export function GameOver({game}) {
    let players = Object.values(game.players).sort((p,b)=>total(b)-total(p))
    const navigate = useNavigate()
    const leave=()=>{
        navigate(PATHS.home())
    }
    return <div className={"game-container"}>
        <div className={"game-over-container"}>
            <Rules rules={game.rules}/>
            <div className={"player-list"}>
                <div className={"first-player"}><FinalPlayerPanel player={players[0]} position={1}
                                                                  seasons={game.seasons}/>
                </div>
                <div className={"other-players"}>
                    {players.slice(1).map((player, i) => <div className={"other-player"}><FinalPlayerPanel
                        player={player}
                        position={i + 2}
                        seasons={game.seasons}/>
                    </div>)}

                </div>
            </div>
            <button className={"leave-button"} onClick={leave}>Leave</button>
        </div>
    </div>
}