import axios from 'axios';
import {AuthService} from "./auth";

const API_BASE_URL = `http://${window.location.host}/api`; // Replace with your backend API URL

// Set authorization header with JWT token
const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};
const instance = axios.create();

instance.interceptors.request.use(async (config) => {
  // Get the token from the cookie
    const idToken = await AuthService.getIdToken();

  if (idToken) {
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});
const api = {
    auth: {
        checkLogin:()=>instance.get(`${API_BASE_URL}/auth/check`),
        currentUser:()=>instance.get(`${API_BASE_URL}/auth/current`),
        updateUser:(username)=>instance.post(`${API_BASE_URL}/auth/current`,{username:username})
    },
    game: {
        create: (gameData) => instance.post(`${API_BASE_URL}/game`, gameData),
        join: (gameId,gameData) => instance.post(`${API_BASE_URL}/game/join/${gameId}`, gameData),
        list: (forUser) => instance.get(`${API_BASE_URL}/game${forUser?'?forUser=true':''}`),
        gameOfUser:(id) => instance.get(`${API_BASE_URL}/game/find/user/${id}`),
        delete:(game) => instance.delete(`${API_BASE_URL}/game/${game.id}`)
    },
    // Add more endpoints as needed
    SOCKET_ROOT_URL: process.env.REACT_APP_SOCKET_IO_ADDRESS,
    SOCKET_PATH: "/api/game/ws"
};

export { api, setAuthToken };
