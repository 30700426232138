import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import generateRandomUsername from "../../utils/utils";
import {createGame, joinGame} from "../../services/game";
import {PATHS} from "../../services/paths";
import styles from "./Create.module.scss"
function Create(props) {
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const [name,setName] = useState(generateRandomUsername());
    const [creating,setCreating] = useState(false);
    const handleNameChange = (e)=>{setName(e.target.value)}
    const handleCreateGame = ()=>{
        let userId = user?.uid;
        let username = user?.username;
        setCreating(true)
        createGame(userId,name).then(({data:game})=>{
            joinGame(game,userId,username).then((game)=>{
                navigate(PATHS.game(game))
            })
        }).catch((reason)=>{
            setCreating(false)
        })
    }
    const back = ()=>{
        navigate(PATHS.home())
    }
    return (
        <div className={"container"}>
            <div className={"content "+styles.create}>
                <button className={"back"} onClick={back}>&lt;back</button>
                <h1>Create Game</h1>
                <div>
                    <div>Pick a game name</div>
                    <input type="text" onChange={handleNameChange} value={name}/>
                    <button className={"create-button"} onClick={handleCreateGame} disabled={creating}>{!creating?"Create":"Creating"}</button>
                </div>
            </div>
        </div>
    );
}

export default Create;