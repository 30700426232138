import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan} from "@fortawesome/free-solid-svg-icons";

export const PlayerItem = ({ player,isAdmin,onKick}) => {
    function handleKick(){
        onKick(player)
    }

    return (
        <div className="player-item">
            {player.status==="Ready" && "✔️"}
            {player.status==="Waiting" &&"⏳" }
            <div className="player-name">{player.username}</div>
            {isAdmin && <div>
                <button className={"icon"} onClick={handleKick}>
                    <FontAwesomeIcon icon={faBan}/>
                </button>
            </div>}
        </div>
    );
};


