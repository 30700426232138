import * as PropTypes from "prop-types";
import "./PlayerScores.scss"
function Score({score,active,done}) {
    return <div className={"score "+(done?" done ":" ")+(active?" active ":" ")}>
        <div className={"r1"}>{score.rules[0]}</div>
        <div className={"r2"}>{score.rules[1]}</div>
        <div className={"coins"}>{score.coin}</div>
        <div className={"monsters"}>-{score.monster}</div>
        <div className={"total"}>{score.total}</div>
    </div>
}

Score.propTypes = {score: PropTypes.any};

export function PlayerScores({player,seasons,currentIndex}) {
    const scores = player.scores
    return <div className={"player-scores"}>
        <div className={"scores"}>{seasons.map((season,index) => <Score score={scores[season.type]} active={currentIndex===index} done={currentIndex>index}/>)}</div>
    </div>
}