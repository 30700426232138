import React, {useContext} from "react";
import "./ControlTile.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons/faRotate";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons/faRotateRight";
import {faRotateLeft} from "@fortawesome/free-solid-svg-icons/faRotateLeft";
import {faArrowsUpDown} from "@fortawesome/free-solid-svg-icons/faArrowsUpDown";
import {faArrowsLeftRight} from "@fortawesome/free-solid-svg-icons/faArrowsLeftRight";
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import {PreviewContext} from "../../contexts/PreviewContext";
export function ControlTile() {
    const {rotateCW,rotateCCW,mirrorVertical,mirrorHorizontal,cancel,validate,validPlacement} = useContext(PreviewContext)

    return <div className={"control-container"}>
        <div className={"control"}>
            <FontAwesomeIcon onClick={() => rotateCW()} className={"btn"} icon={faRotateRight}/>
            <FontAwesomeIcon onClick={() => rotateCCW()} className={"btn"} icon={faRotateLeft}/>
            <FontAwesomeIcon onClick={() => mirrorVertical()} className={"btn"} icon={faArrowsUpDown}/>
            <FontAwesomeIcon onClick={() => mirrorHorizontal()} className={"btn"} icon={faArrowsLeftRight}/>
            <FontAwesomeIcon onClick={() => cancel()} className={"btn cancel"} icon={faCircleXmark}/>
            <FontAwesomeIcon onClick={() => validate()}
                             className={"btn validate " + (validPlacement ? "" : " disabled")} icon={faCircleCheck}/>

        </div>
    </div>

}