import React, {useContext, useState} from "react";
import "./PlayerStatuses.scss"
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "../../../contexts/AuthContext";

function PlayerStatus({player}) {
    let {user} = useContext(AuthContext)
    const currentUser = user?.uid===player.id
    return <div className={"player-status"}>
        <div className={"player-status"}>
            {player.status==="Placed" && "✔️"}
            {player.status==="Placing" &&"⏳" }
        </div>
        <div className={"player-name "+(currentUser?"current-player ":"")}>{player.username}</div>
    </div>
}



export function PlayerStatuses({players}) {
    let  classes =""
    if(players.length>4)
        classes=" small "
    return <div className={"player-statuses "+classes}>
        {Object.values(players).map(player => <PlayerStatus player={player}/>)}
    </div>
}