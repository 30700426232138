import "./MapDisplay.scss"
import React, {useContext} from "react";
import {PreviewContext} from "../../contexts/PreviewContext";
import {ControlTile} from "./ControlTile";
import {OverlayTile} from "./OverlayTile";
import {MapTile} from "./MapTile";
import {computePosition} from "../../utils/utils";
import {useDrag} from "react-dnd";


function MapDisplay({board, attacking, onDragOver}) {
    const previewPosition = [];
    let previewType = null;
    let controlPosition = null;
    const {preview} = useContext(PreviewContext)
    if (preview != null) {
        const {shape} = preview;
        previewType=shape.type;
        const positions = computePosition(preview);
        previewPosition.push(...positions.map((([i, j]) => `${i}:${j}`)))

        controlPosition = [Math.max(...positions.map(([x, y]) => x )), Math.max(...positions.map(([x, y]) => y+1))]
        if (controlPosition[0] >=board.length-7) {
            controlPosition[0] = Math.min(...positions.map(([x, y]) => x - (controlPosition[0]-(board.length-7))))
        }
        if(controlPosition[1]>=board.length){
            controlPosition[1] = Math.min(...positions.map(([x, y]) =>y -2))
        }
    }

    const onDrop = (tile) => {
        console.log(tile)
    }
    return (
        <div className="map-display">
            <div className="base-grid">

                {board.map((row, rowIndex) => (
                    <div key={rowIndex} className="map-row">
                        {row.map((tile, colIndex) => (
                            <MapTile key={`${rowIndex}-${colIndex}`} tile={tile}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {attacking!==null && <div className={"attacking"}>
                <div className={"target"}> {attacking.username}</div>
            </div>}
            <div className="overlay-grid map-preview">
                {board.map((row, rowIndex) => (
                    <div key={rowIndex} className="map-row">
                        {row.map((tile, colIndex) => {
                            let content = null;
                            const positionString = `${colIndex}:${rowIndex}`;
                            if (previewPosition.includes(positionString)) {
                                content = previewType
                            }
                            let relativePosition = null;
                            if(preview!==null){
                                relativePosition = [preview.shape.origin[0]-preview.x,preview.shape.origin[1]-preview.y]
                                relativePosition = [relativePosition[0]+colIndex,relativePosition[1]+rowIndex]
                            }
                            // if(controlPosition!==null && controlPosition[0]===colIndex && controlPosition[1]===rowIndex){
                            //     return <ControlTile />
                            // }
                            return (
                                <OverlayTile key={`${rowIndex}:${colIndex}`} content={content} tile={tile}
                                             onDrop={onDrop}
                                             onDragOver={onDragOver} shape={preview?.shape}
                                            relativePosition={relativePosition}
                                />
                            );
                        })}
                    </div>
                ))}

            </div>
        </div>

    );
}

export default MapDisplay;
