import React from "react";
import "./SeasonCard.scss"
export function SeasonCard({season}) {
    return <div className={"season " + season.type}>
        <div className={"progress-chip"}>
            <div className={"progress"}>{season.advancement}</div>
            <div className={"limit"}>{season.limit}</div>
        </div>
        
    </div>;
}