import "./Rules.scss"
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
export function Rules({rules}) {
    const [focussed,setfocussed] =useState(null);
    const handleFoccused =(rule)=>{
        if(focussed===rule){
            setfocussed(null);
        }else{
            setfocussed(rule)
        }
    }
    return <div className={"rules"}>
        {Object.values(rules).map(rule=> <div className={"rule"}>
            <img onClick={() => handleFoccused(rule)} src={`/assets/rules/${rule}.jpg`} alt=""/>
        <FontAwesomeIcon icon={faInfoCircle} className={"rule-icon"}/>
        </div>)}
        {focussed && <div className={"focussed-container"} onClick={() => handleFoccused(null)}>

                <img className={"focussed rule"} src={`/assets/rules/${focussed}.jpg`} alt="" />

        </div>}
    </div>
}