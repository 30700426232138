import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Welcome from './components/Home/Welcome';
import GameContainer from "./components/Game/GameContainer";
import {DndProvider} from "react-dnd";
import {TouchBackend} from 'react-dnd-touch-backend'
import {isMobile} from "react-device-detect";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ProtectedRoute} from "./components/Auth/ProtectedRoute";
import Create from "./components/Home/Create";
import Login from "./components/Auth/Login";


function App() {
    let backend = TouchBackend;
    if (!isMobile) {
        backend = HTML5Backend;
    }
    return (


        <Router>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Welcome/>}/>
                <Route path="/create" element={<ProtectedRoute><Create/></ProtectedRoute>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/game/:id" element={

                        <DndProvider backend={backend}>
                            <GameContainer/>
                        </DndProvider>
                    }/>
            </Routes>
            {/*<Footer />*/}
        </Router>
    );
}

export default App;
