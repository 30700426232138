import React, {useContext} from 'react';
import {PlayerItem} from "./PlayerItem";
import "./Lobby.scss"
import {PATHS} from "../../../services/paths";
import { useNavigate} from "react-router-dom";
import {AuthContext} from "../../../contexts/AuthContext";

export const Lobby = ({ game, onReady,onJoin,onKick}) => {
    const { players } = game;
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();
    const player = players[user?.uid]
    const handleReady = (playerId) => {
        onReady()
    };
    const handleJoin = ()=>{
        onJoin()
    };
    function handleKick(player){
        onKick(player)
    }
    function handleBack() {
        navigate(PATHS.home())
    }
    const isAdmin = user?.uid===game.creatorId

    function handleLogin() {
        navigate(PATHS.login())
    }

    return (
        <div className={"container"}>
            <div className="content">
                <div>
                    <button className={"back"} onClick={handleBack}>&lt; back</button>
                    <h2>Lobby</h2>
                </div>
                <div className="player-list">
                    {Object.values(players).map((player) => (
                        <PlayerItem key={player.id} player={player} isAdmin={isAdmin} onKick={handleKick}/>
                    ))}
                </div>
                {player !== undefined &&
                    <button className={"ready-button"} onClick={handleReady} disabled={player.status === 'Ready'}>
                        {player.status === 'Ready' ? "Waiting" : 'Ready'}
                    </button>
                }
                {player === undefined && user?.uid!==undefined && <button className={"ready-button"} onClick={handleJoin}>
                    Join
                </button>
                }
                {player === undefined && user?.uid===undefined && <button className={"ready-button"} onClick={handleLogin}>Login to join</button>}
            </div>
        </div>
    );
};


