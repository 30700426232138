import Tile from "./Tile";
import React from "react";

export function MapTile({tile, onDrop, onDragOver}) {


    const content = tile.content;
    const ruin = tile.isRuin;

    return <div><Tile content={content} ruin={ruin}/></div>
}