import {useDrag, useDrop} from "react-dnd";
import React, {useEffect} from "react";
import {getEmptyImage} from "react-dnd-html5-backend";
import Tile from "./Tile";

export function OverlayTile({content, onDragOver, onDrop, tile, shape, relativePosition}) {
    const [{isDragging}, drag, preview] = useDrag(() => {
        return ({
            type: 'shape',
            item: {...shape, origin: relativePosition},
            collect: monitor => {
                return ({
                    isDragging: !!monitor.isDragging(),
                });
            },
        });
    }, [shape, content]);
    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true})
    }, [])

    const [, drop] = useDrop(() => ({
        accept: "shape",
        drop: (item) => onDrop(tile, item),
        hover: (item) => onDragOver(tile.x, tile.y, item),
    }))
    return <div ref={drop}>
        <div ref={drag} style={{width: "100%", height: "100%"}} className={"overlay-tile "+(content!==null?"filled ":"")}>
            <Tile content={content}/>
        </div>
    </div>
}