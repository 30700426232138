import React, {useContext} from 'react';
import "./Navbar.scss"
import {useNavigate} from "react-router-dom";
import {PATHS} from "../services/paths";
import {AuthContext} from "../contexts/AuthContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {UserTag} from "./UserTag";

function Login(props) {
    const navigate = useNavigate();
    const handleLogin = () => {
        navigate(PATHS.login())
    }
    return <button className={"login"} onClick={handleLogin}><FontAwesomeIcon icon={faUser}/> Login</button>
}

function Navbar(props) {
    const navigate = useNavigate()
    const {user,} = useContext(AuthContext);
    const handleHome = () => {
        navigate(PATHS.home())
    }
    return (
        <div className={"navbar"}>
            <div className={"game-title"} onClick={handleHome}>Qartographer</div>
            {user === null && <Login/>}
            {user !== null && <UserTag/>}
        </div>
    );
}

export default Navbar;