import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {AuthContext} from '../../contexts/AuthContext';
import generateRandomUsername from "../../utils/utils";
import {createGame, isInGame, joinGame, listGames, listMyGames} from "../../services/game";
import {api} from "../../services/api";
import {PATHS} from "../../services/paths";
import "./Welcome.scss"

function GameListElement({game}) {
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const [deleting,setDeleting] = useState(false)
    const handleJoin = () => {
        navigate(PATHS.game(game))
    }
    const handleDelete = async ()=>{
        setDeleting(true)
        const done = await api.game.delete(game);
        if(!done){
            setDeleting(false);
        }
    }


    let actionButtons = [<button key="join" onClick={handleJoin}>Join</button>];
    let statusTag = "Lobby"
    let isInGame = game.playerOrder.includes(user?.uid) !== undefined
    if (game.stage === "Running") {
        if (isInGame) {
            actionButtons = [<button key="watch" onClick={handleJoin}>Resume</button>]
        } else {
            actionButtons = [<button key="watch" onClick={handleJoin}>Watch</button>]

        }
        statusTag = "Running"
    } else if (game.stage === "Over") {
        actionButtons = [<button key="end score" onClick={handleJoin}>See End Score</button>]
        statusTag = "Done"
    }
    if (game.creatorId === user?.uid) {
        actionButtons = [<button key="delete" onClick={handleDelete}>delete</button>, ...actionButtons]
    }

    return <div className={"game-item "+(deleting?"deleting":"")} onClick={handleJoin}>
        <div className={"game-name"}>{game.name}
            <div className={"game-status"}>{statusTag}</div>
        </div>
        {actionButtons}
    </div>
}

function Welcome() {
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const [games, setGames] = useState(null)
    const [myGames, setMyGames] = useState(false)


    useEffect(() => {
        async function loadGames() {
            let gameList
            if (myGames) {
                gameList = await listMyGames();
            } else {
                gameList = await listGames();
            }
            setGames(gameList)
        }

        loadGames();
        const interval = setInterval(() => loadGames(), 1000)
        return () => {
            clearInterval(interval)
        }
    }, [user, myGames])

    function handleToActiveGames() {
        setMyGames(false)
    }

    function handleToMyGame() {
        setMyGames(true);
    }

    function handleLogin() {
        navigate(PATHS.login())
    }

    return (
        <div className={"container"}>
            <div className={"content"}>
                <h2>Qartographer</h2>
                <div className={"profile"}>
                    {user && <div>Welcome <div style={{textDecoration: "underline"}}>{user.username}</div></div>}
                    {!user && <div>
                        <div>Welcome Visitor</div>
                        <div><button onClick={handleLogin}>Login</button> to play</div>
                    </div>}
                </div>
                <div className={"filter-selector"}>
                    <h4 className={"left " + ((!myGames) ? "active" : "")} onClick={handleToActiveGames}>Active
                        Games</h4>
                    <h4 className={"right " + ((myGames) ? "active" : "")} onClick={handleToMyGame}>My Games</h4>
                </div>
                <div className={"game-list"}>
                    {games === null && <div>Loading</div>}
                    {games?.map(game => <GameListElement game={game}/>)}
                </div>
                <button onClick={() => navigate(PATHS.create())}>Create Game</button>

            </div>

        </div>
    );
}

export default Welcome;
