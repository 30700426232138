import { useDrag } from 'react-dnd';
import Tile from '../Tile';
import {Fragment, useContext, useEffect} from "react";
import {getEmptyImage} from "react-dnd-html5-backend";
import {PreviewContext} from "../../../contexts/PreviewContext";

function DraggableTile({ x, y, type, isRuin,shape,relativePosition,shapeKey,coin}) {
    const [{ isDragging }, drag,preview] = useDrag(() => ({
        type: 'shape',
        item: { shape, type, isRuin,origin:relativePosition,shapeKey:shapeKey,coin:coin},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }),[shape]);
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [])

    return <div
        className={"draggable-tile"}
        ref={drag}
        style={{gridColumnStart: x + 1, gridRowStart: y + 1}}
    >
        <Tile content={type} isRuin={isRuin}/>
    </div>;
}

function DraggableShape({ shape, type, isRuin,shapeKey,coin }) {
    const previewShape=useContext(PreviewContext)

    // Display the shape as a grid
    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: `repeat(4, 1fr)`,
        gridTemplateRows: `repeat(4, 1fr)`,
        justifyContent: 'center',
        alignItems: 'center',
        opacity:(shapeKey===previewShape?.preview?.shape?.shapeKey)?0:1,

        width:"100%",
    };

    let  xOffset=0
    if(Math.max(...shape.map(x=>x[0]))<3){
        xOffset=1
    }
    let  yOffset=0
    if (shape.length===1){
        gridStyle.gridTemplateColumns="repeat(1, 1fr)"
        gridStyle.gridTemplateRows="repeat(1, 1fr)"
        gridStyle.height="100%"
        xOffset=0;
    }
    return (
        <div>
            <div className={"draggable-shape"}
                 style={gridStyle}
            >
                {shape.map(([x, y], i) => {

                    return <DraggableTile key={i} x={x + xOffset} y={y + yOffset} type={type} isRuin={isRuin}
                                          shape={shape} relativePosition={[x, y]} shapeKey={shapeKey} coin={coin}/>;
                })}
            </div>
        </div>
    );
}

export default DraggableShape;
