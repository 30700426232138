import React, {useContext, useEffect, useState} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import MapDisplay from './MapDisplay';

import {AuthContext} from "../../contexts/AuthContext";
import {isInGame, subscribe} from "../../services/game";
import CurrentCard from "./Card/CurrentCard";
import {CustomDragLayer} from "./Card/CustomDragLayer";
import "./GameContainer.scss"
import {PreviewContext} from "../../contexts/PreviewContext";
import {
    canPlaceCard,
    canPlaceShape,
    checkInBound,
    computePosition,
    getNextPlayer,
    isPlacementValid,
    mirrorHorizontally,
    mirrorVertically,
    rotateCCW,
    rotateCW
} from "../../utils/utils";
import * as PropTypes from "prop-types";
import {Lobby} from "./Lobby/Lobby";
import {PATHS} from "../../services/paths";
import {PlayerStatuses} from "./PlayerStatus/PlayerStatuses";
import {Rules} from "./Rules/Rules";
import {PlayerScores} from "./scores/PlayerScores";
import {CoinColumn} from "./CoinColumn/CoinColumn";
import {SeasonCard} from "./SeasonCard/SeasonCard";
import {GameOver} from "./GameOver";
import {ControlTile} from "./ControlTile";
import {SplashScreen} from "../Home/SplashScreen";

Lobby.propTypes = {game: PropTypes.any};

PlayerStatuses.propTypes = {};

Rules.propTypes = {rules: PropTypes.any};

PlayerScores.propTypes = {};

CoinColumn.propTypes = {player: PropTypes.any};

SeasonCard.propTypes = {season: PropTypes.any};

GameOver.propTypes = {game: PropTypes.any};

function GameContainer() {
    const { id } = useParams();
    const [gameState, setGameState] = useState(null);
    const { user } = useContext(AuthContext);
    const [preview,setPreview] = useState(null)
    const [actions,setActions] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        let close=undefined;
        let subscription = subscribe(user?.uid, user?.username,id, (gameState) => {
            setGameState(gameState);
        },(error)=>{
            if(error==="UNKNOWN_ID"){
                navigate(PATHS.home())
            }
        });
        setActions(subscription.actions)
        close= subscription.close;

        return () => {
            close && close();
        };
    }, [id, user]);
    useEffect(()=>{
        if(gameState!==null && Object.keys(gameState.players).includes(user?.uid)){
            actions?.join(user?.uid,user.username)
        }
    },[actions,user])
    useEffect(()=>{
        if(gameState!==null && gameState.stage==="Delete"){
            setTimeout(()=>navigate(PATHS.home()),1000);
        }
    },[gameState])
    const drawn = gameState?.currentSeason?.drawn.length
    const playerStatus = gameState?.players[user?.uid]?.status
    useEffect(()=>{
        setPreview(null);
    },[drawn,playerStatus])
    if (!gameState) return <SplashScreen/>;
    if(gameState.stage==="Delete"){
        return <SplashScreen title={"Game Deleted"} message={"Redirecting you"}/>
    }
    const handleJoin =()=>{
        actions?.join(user?.uid,user.username)
    }
    if (gameState.stage==="Lobby"){
        const onReady = ()=>{
            actions.ready(user?.uid)
        }
        function handleKick(player){
            actions.kick(player)
        }
        return <div className={"game-container"}>
            <Lobby game={gameState} onReady={onReady} onJoin={handleJoin} onKick={handleKick}/>
        </div>
    }
    if (gameState.stage==="Over"){
        return <PreviewContext.Provider value ={{preview:null,
            rotateCW:null,
            rotateCCW:null,
            mirrorHorizontal:null,
            mirrorVertical:null,
            cancel:null,
            validate:null,
            validPlacement:false
        }}><GameOver game={gameState}/></PreviewContext.Provider>
    }
    const currentCard = gameState.currentSeason.drawn[0]
    let player = gameState.players[user?.uid];
    let attackingPlayer=null;
    if(player===undefined){
        player = gameState.players[gameState.playerOrder[0]]
    }
    if(currentCard.boardOffset!==undefined) {
        player = getNextPlayer(gameState, user, currentCard.boardOffset);
        attackingPlayer=player
    }

    const handleDragOverBoard = (x,y,shape)=>{
        [x,y]=checkInBound(shape,[x,y])
        setPreview({x, y,shape});
    }
    const handleRotateCCW=()=>{
        let shape = rotateCCW(preview.shape.shape)
        shape = {...preview.shape,shape:shape};
        const [x,y]=checkInBound(shape,[preview.x,preview.y])
        setPreview({...preview,x:x,y:y,shape: shape})
    }
    const handleRotateCW=()=>{
        let shape = rotateCW(preview.shape.shape)
        shape = {...preview.shape,shape:shape};
        const [x,y]=checkInBound(shape,[preview.x,preview.y])
        setPreview({...preview,x:x,y:y,shape: shape})
    }
    const handleMirrorHorizontal=()=>{
        let shape = mirrorHorizontally(preview.shape.shape)
        shape = {...preview.shape,shape:shape};
        const [x,y]=checkInBound(shape,[preview.x,preview.y])
        setPreview({...preview,x:x,y:y,shape: shape})
    }
    const handleMirrorVertical=()=>{
        let shape = mirrorVertically(preview.shape.shape)
        shape = {...preview.shape,shape:shape};
        const [x,y]=checkInBound(shape,[preview.x,preview.y])
        setPreview({...preview,x:x,y:y,shape: shape})
    }
    const handleCancel =()=>{
        setPreview(null)
    }
    const handleValidate=()=>{
        const shape = computePosition(preview);
        actions.placeShape({x:preview.x,y:preview.y,type:preview.shape.type,shape: shape,coin:preview.shape.coin})
    }
    let validPlacement =false;
    if(preview!==null){
        validPlacement=isPlacementValid(preview,player.board,currentCard,gameState.currentSeason,!canPlaceCard(player.board,currentCard,gameState.currentSeason));
    }

    return (
        <PreviewContext.Provider value={{preview,
            rotateCW:handleRotateCW,
            rotateCCW:handleRotateCCW,
            mirrorHorizontal:handleMirrorHorizontal,
            mirrorVertical:handleMirrorVertical,
            cancel:handleCancel,
            validate:handleValidate,
            validPlacement
        }}>
            <div className={"game-container"}>
                <div className="game-content">

                    <CustomDragLayer hide={preview != null}/>
                    <PlayerStatuses players={gameState.players}/>
                    <PlayerScores player={player} seasons={gameState.seasons}
                                  currentIndex={gameState?.seasons.map(s => s.type).indexOf(gameState?.currentSeason.type)}/>
                    <div className={"grid-row"}>
                        <CoinColumn player={player}/>
                        <div style={{position: "relative"}}><MapDisplay board={player.board} attacking={attackingPlayer}
                                                                        onDragOver={handleDragOverBoard}/>
                            {preview !== null && <ControlTile/>}
                        </div>
                        <div style={{width: "100%"}}></div>
                    </div>
                    <div className={"card-row"}>
                        <SeasonCard season={gameState.currentSeason}/>
                        <CurrentCard card={currentCard} season={gameState.currentSeason}
                                     active={playerStatus === "Placing"} board={player.board}/>
                    </div>
                    <Rules rules={gameState.rules}/>
                </div>
            </div>
        </PreviewContext.Provider>

    );
}

export default GameContainer;
